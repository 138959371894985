import { green, grey, orange, red } from '@material-ui/core/colors';

export const COMMUNICATION_TYPES = [
  { id: 'email', name: 'Email' },
  { id: 'pec', name: 'PEC' },
  { id: 'sms', name: 'SMS' },
  { id: 'push', name: 'Push' },
];

export const COMMUNICATION_STATUSES = [
  { id: 'unsent', name: 'Non inviata' },
  { id: 'processing', name: 'In corso' },
  { id: 'sent', name: 'Inviata' },
  { id: 'error', name: 'Errore' },
];

export const COMMUNICATION_PRIORITIES = [
  { id: 'normal', name: 'Normale' },
  { id: 'high', name: 'Alta' },
];

export const COMMUNICATION_TEMPLATES = [
  { id: 'be-crea-password', name: 'BE - Crea password Back-Office' },
  { id: 'be-interno-notifiche-non-lette', name: 'BE - Interno - Notifiche non lette' },
  {
    id: 'be-interno-pagamento-vincita',
    name: "BE - Interno - Pagamento vincita da parte dell'aggiudicatario",
  },
  {
    id: 'be-interno-pagamento-vincita-proprietario',
    name: 'BE - Interno - Pagamento vincita proprietario',
  },
  {
    id: 'be-interno-spedizione-vincitore',
    name: 'BE - Interno - Spedizione vincitore',
  },
  {
    id: 'be-interno-spedizione-ricevuta-proprietario',
    name: 'BE - Interno - Spedizione ricevuta proprietario',
  },
  {
    id: 'be-interno-spedizione-non-ricevuta-proprietario',
    name: 'BE - Interno - Spedizione non ricevuta proprietario',
  },
  {
    id: 'be-interno-apertura-controversia-proprietario',
    name: 'BE - Interno - Apertura controversia proprietario',
  },
  {
    id: 'be-interno-chiusura-controversia-proprietario',
    name: 'BE - Interno - Chiusura controversia proprietario',
  },
  {
    id: 'be-interno-non-risoluzione-controversia-vincitore',
    name: 'BE - Interno - Non risoluzione controversia vincitore',
  },
  {
    id: 'be-interno-non-risoluzione-controversia-proprietario',
    name: 'BE - Interno - Non risoluzione controversia proprietario',
  },
  {
    id: 'be-interno-nuovo-messaggio-controversia-vincitore',
    name: 'BE - Interno - Nuovo messaggio controversia vincitore',
  },
  {
    id: 'be-interno-nuovo-messaggio-controversia-proprietario',
    name: 'BE - Interno - Nuovo messaggio controversia proprietario',
  },
  {
    id: 'be-interno-prenotazione-sala-asta-confermata',
    name: "BE - Interno - Prenotazione sala d'asta confermata",
  },
  {
    id: 'be-interno-prenotazione-sala-asta-rifiutata',
    name: "BE - Interno - Prenotazione sala d'asta rifiutata",
  },
  {
    id: 'be-interno-richiesta-prenotazione-sala-asta',
    name: "BE - Interno - Richiesta prenotazione sala d'asta",
  },
  {
    id: 'be-interno-richiesta-prenotazione-sala-asta-delegato',
    name: "BE - Interno - Richiesta prenotazione sala d'asta (delegato)",
  },
  {
    id: 'be-interno-riepilogo-offerte-telematiche-ricevute',
    name: 'BE - Interno - Riepilogo offerte telematiche ricevute',
  },
  { id: 'be-iscrizione-offerente', name: 'BE - Richiesta di iscrizione come offerente' },
  { id: 'be-iscrizione-osservatore', name: 'BE - Richiesta autorizzazione come osservatore' },
  { id: 'be-pubblicazione-lotto-esportato', name: 'BE - Pubblicazione lotto esportato' },
  { id: 'be-reset-password', name: 'BE - Reset password' },
  { id: 'be-rilancio-107', name: 'BE - Ricezione Rilancio migliorativo ex. Art. 107 L.F.' },
  { id: 'fe-interno-richiesta-visita', name: 'FE - Interno - Richiesta visita' },
  { id: 'fe-interno-richiesta-info', name: 'FE - Interno - Richiesta info' },
  {
    id: 'fe-interno-richiesta-vivacizzazione',
    name: 'FE - Interno - Ricezione richiesta vivacizzazione',
  },
  { id: 'fe-iscrizione-alla-gara', name: 'FE - Iscrizione alla gara completata' },
  {
    id: 'fe-iscrizione-osservatore',
    name: "FE - Ricezione richiesta d'iscrizione come osservatore",
  },
  { id: 'fe-iscrizione-osservatore-autorizzata', name: 'FE - Iscrizione osservatore autorizzata' },
  { id: 'fe-iscrizione-proprietario', name: 'FE - Iscrizione venditore' },
  { id: 'fe-notifica-inizio-gara', name: 'FE - Notifica inizio gara' },
  { id: 'fe-notifica-proprietario', name: 'FE - Notifica mancata vendita al venditore' },
  { id: 'fe-notifica-ricerche-salvate', name: 'FE - Notifica ricerche salvate' },
  {
    id: 'fe-notifica-vincitore-rifiuto-proprietario',
    name: 'FE - Notifica vincitore di mancato raggiungimento del prezzo di riserva',
  },
  { id: 'fe-nuova-asta-107', name: 'FE - Nuova asta post rilancio migliorativo art. 107' },
  { id: 'fe-nuova-migliore-offerta', name: 'FE - Nuova migliore offerta' },
  { id: 'fe-nuovo-pin', name: 'FE - Nuovo pin' },
  { id: 'fe-partecipazione-autorizzata', name: 'FE - Partecipazione autorizzata' },
  { id: 'fe-partecipazione-non-autorizzata', name: 'FE - Partecipazione non autorizzata' },
  { id: 'fe-perdita', name: 'FE - Comunicazione Non Aggiudicatari' },
  { id: 'fe-prenota-visita', name: 'FE - Ricezione richiesta di visita' },
  { id: 'fe-recupera-password', name: 'FE - Reimposta password' },
  { id: 'fe-richiesta-cancellazione-account', name: 'FE - Richiesta cancellazione account' },
  { id: 'fe-richiesta-info', name: 'FE - Ricezione richiesta info' },
  { id: 'fe-richiesta-vivacizzazione', name: 'FE - Ricezione richiesta info vivacizzazione' },
  { id: 'fe-rilancio-107', name: "FE - Comunicazione all'offerente del rilancio 107" },
  { id: 'fe-verifica-email', name: 'FE - Verifica email' },
  { id: 'fe-vincita', name: 'FE - Aggiudicazione' },
  { id: 'fe-vincita-pre-107', name: 'FE - Aggiudicazione provvisoria Art. 107' },
];

export enum CommunicationType {
  Email = 'email',
  PEC = 'pec',
  SMS = 'sms',
  Push = 'push',
}

/// region COLORS

type CommunicationColors = {
  unsent: [string, string];
  processing: [string, string];
  sent: [string, string];
  error: [string, string];
};

export const COMMUNICATION_UNSENT_BACKGROUND_COLOR: string = grey[700];
export const COMMUNICATION_UNSENT_TEXT_COLOR: string = grey[50];
export const COMMUNICATION_PROCESSING_BACKGROUND_COLOR: string = orange[500];
export const COMMUNICATION_PROCESSING_TEXT_COLOR: string = grey[900];
export const COMMUNICATION_SENT_BACKGROUND_COLOR: string = green[500];
export const COMMUNICATION_SENT_TEXT_COLOR: string = grey[900];
export const COMMUNICATION_ERROR_BACKGROUND_COLOR: string = red[700];
export const COMMUNICATION_ERROR_TEXT_COLOR: string = grey[50];

export const COMMUNICATION_COLORS: CommunicationColors = {
  unsent: [COMMUNICATION_UNSENT_BACKGROUND_COLOR, COMMUNICATION_UNSENT_TEXT_COLOR],
  processing: [COMMUNICATION_PROCESSING_BACKGROUND_COLOR, COMMUNICATION_PROCESSING_TEXT_COLOR],
  sent: [COMMUNICATION_SENT_BACKGROUND_COLOR, COMMUNICATION_SENT_TEXT_COLOR],
  error: [COMMUNICATION_ERROR_BACKGROUND_COLOR, COMMUNICATION_ERROR_TEXT_COLOR],
};

/// endregion
