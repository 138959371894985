import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppNumberInput, AppTextInput } from '../../../Components/ui/input';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'carsAndCycles';

const TerzoLivelloDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData } = props;

  if (formData.fkCategory !== 5) return <React.Fragment />;

  return (
    <AppCard title="Dettagli personalizzati" disabled={mode === 'show'}>
      <AppTextInput source={`${BASE_SOURCE}.carType`} label="Carrozzeria" md={4} />
      <AppTextInput source={`${BASE_SOURCE}.showroom`} label="Sede" md={4} />
      <AppTextInput source={`${BASE_SOURCE}.gearbox`} label="Cambio" md={4} />
      <AppNumberInput source={`${BASE_SOURCE}.seats`} label="Numero di posti" md={4} />
      <AppNumberInput source={`${BASE_SOURCE}.displacement`} label="Cilindrata" md={4} />
      <AppNumberInput source={`${BASE_SOURCE}.doors`} label="Porte" md={4} />
      <AppTextInput source={`${BASE_SOURCE}.details2Title`} label="Titolo dettagli 2" md={12} />
      <AppTextInput
        source={`${BASE_SOURCE}.details2Text`}
        label="Testo dettagli 2"
        md={12}
        multiline
        expandable
      />
      <AppTextInput source={`${BASE_SOURCE}.details3Title`} label="Titolo dettagli 3" md={12} />
      <AppTextInput
        source={`${BASE_SOURCE}.details3Text`}
        label="Testo dettagli 3"
        md={12}
        multiline
        expandable
      />
    </AppCard>
  );
};

export default TerzoLivelloDetails;
